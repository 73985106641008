import React, { useEffect } from 'react';

import { useScreenType, useScreenTypeUpdate } from "../../components/context/MixContext";

// import Header from '../../layout/Header/Header';
import Player from '../../components/Video/Player';

import { motion } from "framer-motion";

import './Intro.css';

const Intro = () => {

    // screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();

    const introNav = (_navtype) => {
        if ( _navtype === "prev") {
            setScreenType("home");
        } else {
            setScreenType("tp");
        }  
    }

    useEffect(() => {
        const footnote = document.getElementById("footnotes");
        footnote.classList.add("footnotes-wide");
        footnote.innerHTML = "<p>The statements made in this presentation reflect only the personal opinions and experiences of the speaker and do not necessarily reflect the opinions of Guerbet or any other institution with which they are affiliated. Please consult the prescribing information in your country.</p>";
    }, []);

    return (

        <motion.div id="intro"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }} 
            transition={{ delay: .2, duration: .5 }}>
                {/* { <Header navfrom="intro" /> } */}
                <div className="intro">
                    <h1 className="underline">The PICTURE Trial</h1>
                    <div className="intro-wrap">
                        <div className="intro-column column1">
                            <h1>Efficacy and Safety of Gadopiclenol for Contrast-Enhanced MRI of the Central Nervous System</h1>
                            <div className="fade-line"></div>
                            <h2>The PICTURE Randomized Clinical Trial</h2>
                            <p>Loevner LA, Kolumban B, Hutóczki G, Dziadziuszko K, Bereczki D, Bago A, Pichiecchio A. Invest Radiol. 2023 May 1;58(5):307-313.</p>
                        </div>
                        <div className="intro-column column2">
                            <div className="intro-video-wrap">
                            { <Player vid={'assets/video/Web-01-The-PICTURE-Trial.mp4'} poster={'assets/video/poster.jpg'} /> }
                            </div>
                            <p>Hear insights on the PICTURE Trial from</p>
                            <h2>Professor Laurie Loevner</h2>
                            <p className="footnote">Recorded September 2023</p>
                        </div>
                    </div>
                </div>

                <div className="button-parent">
                <div className="button-wrapper">
                    <div id="swiper-prev-btn" className="swiper-btn" onClick={() => { introNav("prev"); }}></div>
                    <div id="swiper-next-btn" className="swiper-btn" onClick={() => { introNav("next"); }}></div>
                </div>
            </div>

        </motion.div>
    );
};

export default Intro;