import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide22.css';

const Slide22 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide22">
        <div className="video-wrap">
            { <Player vid={'assets/video/Web-09-Diagnostic-preference.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[2].slides[2].title}</h1>
            <div className="slide-copy-wrap">
                <div className="slide-copy">
                    <p>Images obtained with <span>Elucirem™</span> were in majority preferred to images with Gadovist*</p>
                </div>
            </div>
            <div className="image-wrap flex-center fade">
                <img src={require('../../../assets/images/slide22-1.png')} alt="Chart" />
            </div>
        </div>
        </div>

    );
};

export default Slide22;