import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide20.css';

const Slide20 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide20">
        <div className="video-wrap">
            { <Player vid={'assets/video/Web-07-Lesion-visualization01.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[2].slides[0].title}</h1>
            <div className="slide-copy-wrap">
                <div className="slide-copy">
                    <p>Elucirem™ (0.05 mmol/kg) demonstrated <span>superiority in terms of lesion visualization</span> vs unenhanced MRI</p>
                </div>
            </div>
            <div className="image-wrap flex-center fade">
                <img src={require('../../../assets/images/slide20-1.png')} alt="Chart" />
            </div>
        </div>
        </div>

    );
};

export default Slide20;