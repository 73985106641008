import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide40.css';

const Slide40 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide40">
        <div className="video-wrap">
        { <Player vid={'assets/video/Web-13-Conclusions-of-the-PICTURE-Trial.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[4].slides[0].title}</h1>
            <div className="list-wrap flex-center">
                <div className="col-wrap">
                    <div className="col text-col fade">
                        <div className="top-rule"></div>
                        <div className="left-rule"></div>
                        <ul>
                            <li>Due to reports of NSF and gadolinium retention in the brain and other organs, <span>using the lowest dose of GBCA without compromising diagnostic quality</span> and efficacy in routine practice is <span>recommended</span></li>
                            <li>One way to maintain image quality and diagnostic accuracy while reducing dose is to increase GBCA relaxivity</li>
                            <li>Elucirem™ has the highest relaxivity among currently available GBCAs</li>
                            <li>The PICTURE Trial demonstrated that Elucirem™ can be used at <span>half the gadolinium dose</span> compared to other approved GBCAs to achieve <span>comparable diagnostic efficacy in CNS MRI</span> with a <span>good safety profile</span></li>
                            <li>The need for a lower gadolinium dose could be particularly <span>beneficial</span> for patients requiring <span>repeated MRI, pediatric patients and renally impaired patients</span></li>
                            <li>The lower gadolinium dose could also <span>lower the environmental impact</span> of GBCAs</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide40;