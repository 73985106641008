import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide00.css';

const Slide00 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide00">
        <div className="video-wrap">
            { <Player vid={'assets/video/Web-02-Evolving-recommendations.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[0].slides[0].title}</h1>
            <div className="slide-copy-wrap flex-center">
                <div className="slide-copy">
                    <p>Following reports of nephrogenic systemic fibrosis (NSF) and gadolinium retention associated with the use of some GBCAs,<span> major health</span> authorities, <span>recommend</span>:</p>
                </div>
            </div>

            <div className="image-wrap flex-center">
                <div className="col-wrap">
                    <div className="image-col">
                        <img src={require('../../../assets/images/slide00-1.png')} alt="Downward arrow" />
                        <p><span>Reducing</span> the dose of<br/>gadolinium wherever possible</p>
                    </div>
                    <div className="image-col">
                    <img src={require('../../../assets/images/slide00-2.png')} alt="Tick" />
                        <p>But without affecting<br/><span>diagnostic quality</span></p>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide00;