import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide11.css';

const Slide11 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide11">
        <div className="video-wrap">
            { <Player vid={'assets/video/Web-05-Outcomes-and-measures.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[1].slides[1].title}</h1>
            <div className="list-wrap flex-center">
                <div className="col-wrap">
                    <div className="col text-col fade">
                        <div className="top-rule"></div>
                        <div className="left-rule"></div>
                        <h2>Primary criteria</h2>
                        <p><span>Lesion visualization</span> based on 3 parameters (assessed by 3 off-site readers)</p>
                        <ul>
                            <li>Border delineation</li>
                            <li>Internal morphology</li>
                            <li>Contrast enhancement</li>
                        </ul>
                    </div>
                    <div className="col text-col fade">
                        <div className="top-rule"></div>
                        <div className="left-rule"></div>
                        <h2>Key secondary criteria</h2>
                        <ul>
                            <li>Lesion to background ratio (LBR)</li>
                            <li>Enhancement percentage (E%)</li>
                            <li>Contrast to noise ratio (CNR)</li>
                            <li>Overall diagnostic preference </li>
                            <li>Adverse events</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide11;