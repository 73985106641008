import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate, useModalContentUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide23.css';

const Slide23 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    // Modal content
    const modalContentSet = useModalContentUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        modalContentSet(e);
        popupModalSet(true);
    }


    return (
        <div id="slide23">
        <div className="video-wrap">
            { <Player vid={'assets/video/Web-10-Quantitative-parameters.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[2].slides[2].title}</h1>
            <div className="slide-copy-wrap">
                <div className="slide-copy">
                    <ul>
                        <li>E% and LBR were significantly higher with Elucirem™ as compared to Gadovist® for all 3 readers</li>
                        <li>CNR was higher with Elucirem™ for 2 readers</li>
                    </ul>
                </div>
            </div>

            <div className="image-wrap flex-center">
                <div className="col-wrap">
                    <div className="image-col" onClick={() => openModal("slide23-1")}>
                        <p>Percentage of enhancement</p>
                        <img src={require('../../../assets/images/slide23-1.png')} alt="Downward arrow" />
                        <div className="expand"></div>
                    </div>
                    <div className="image-col" onClick={() => openModal("slide23-2")}>
                        <p>Lesion to Background Ratio</p>
                        <img src={require('../../../assets/images/slide23-2.png')} alt="Tick" />
                        <div className="expand"></div>
                    </div>
                    <div className="image-col" onClick={() => openModal("slide23-3")}>
                        <p>Contrast to Noise Ratio</p>
                        <img src={require('../../../assets/images/slide23-3.png')} alt="Tick" />
                        <div className="expand"></div>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide23;