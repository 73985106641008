import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide10.css';

const Slide10 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide10">
        <div className="video-wrap">
            { <Player vid={'assets/video/Web-04-Study-design.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[1].slides[0].title}</h1>
            <div className="slide-copy-wrap">
                <div className="slide-copy">
                    <p>The PICTURE trial was a <span>multi&#8209;center, prospective, randomized, double-blind, controlled</span> and <span>cross&#8209;over phase III study</span> conducted in 11 countries (in Europe, America and Asia)</p>
                </div>
            </div>

            <div className="image-wrap flex-center fade">
                <img src={require('../../../assets/images/slide10-1.png')} alt="Chart" />
            </div>
        </div>
        </div>

    );
};

export default Slide10;