import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide24.css';

const Slide24 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide24">
        <div className="video-wrap">
        { <Player vid={'assets/video/Web-11-Patient-case.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[2].slides[3].title}</h1>
            <div className="slide-copy-wrap flex-center">
                <div className="slide-copy">
                    <p>65-year-old male patient with brain metastases from lung cancer</p>
                </div>
            </div>

            <div className="image-wrap flex-center">
                <div className="col-wrap">
                    <div className="image-col color-fade">
                        <img src={require('../../../assets/images/slide24-1.png')} alt="Elucirem™ 0.05 mmol/kg" />
                    </div>
                    <div className="image-col">
                    <img src={require('../../../assets/images/slide24-2.png')} alt="Gadovist® 0.1 mmol/kg" />
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide24;