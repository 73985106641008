import React from 'react';

import './Slide22.css';

const Slide22_3_popup = () => {
    return (
        <div className="popup">
            <h2>Contrast to Noise Ratio</h2>
            <div className="popup-img-wrap">
                <img src={require('../../../assets/images/slide23-3.png')} alt="Contrast to Noise Ratio" />
            </div>
        </div>
    );
};

export default Slide22_3_popup;