import React, { useEffect } from 'react';
import { useModalContent } from "../../components/context/ModalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './ModalContent.css'
import Slide23_1_popup from '../Slides/Slide/Slide23_1_popup';
import Slide23_2_popup from '../Slides/Slide/Slide23_2_popup';
import Slide23_3_popup from '../Slides/Slide/Slide23_3_popup';

const ModalContent = (props) => {

    // Modal content
    const getModalContent = useModalContent();

    return (
        <div id="modal">
            <div className="modal-header-transparent">
                <div className="close-btn" onClick={props.close}><FontAwesomeIcon icon={faTimes} /></div>
            </div>
            <div id="modal-content" className="modal-content">
                { getModalContent === "slide23-1" ? <Slide23_1_popup /> : null }
                { getModalContent === "slide23-2" ? <Slide23_2_popup /> : null }
                { getModalContent === "slide23-3" ? <Slide23_3_popup /> : null }
            </div>
        </div>
    );
    
};

export default ModalContent;