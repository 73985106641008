import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide30.css';

const Slide30 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide30">
        <div className="video-wrap">
        { <Player vid={'assets/video/Web-12-Safety.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[3].slides[0].title}</h1>
            <div className="list-wrap flex-center">
                <div className="col-wrap">
                    <div className="col text-col fade">
                        <div className="top-rule"></div>
                        <div className="left-rule"></div>
                        <ul>
                            <li>Elucirem™ was shown to have a <span>comparable safety profile</span> to Gadovist<sup>®</sup></li>
                            <li>Overall, <span>14.6% of patients reported at least one AE</span> after MRI with Elucirem™ compared to 17.6% of patients after MRI with Gadovist<sup>®</sup></li>
                            <li>Most AEs were of <span>mild</span> intensity
                                <ul>
                                    <li>98% with Elucirem™ vs 91% with Gadovist<sup>®</sup></li>
                                </ul>
                            </li>
                            <li><span>4.9%</span> of patients reported <span>at least one TEAE</span> related to contrast agent (vs 6.9% among Gadovist<sup>®</sup> patients)
                                <ul>
                                    <li>The most frequent TEAEs were <span>injection site</span> reactions</li>
                                    <li>None were serious</li>
                                </ul>
                            </li>
                            <li><span>No safety concerns</span> were raised from laboratory results or vital signs</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide30;