import React from 'react';

import './Popup01.css'

const Popup01 = () => {
    return (
        <div>
            <h1>Heading Popup 01</h1>
        </div>
    );
};

export default Popup01;