import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate, useModalContentUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide01.css';

const Slide01 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    // Modal content
    const modalContentSet = useModalContentUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide01">
        <div className="video-wrap">
            { <Player vid={'assets/video/Web-03-Next-generation-GBCA-from-Guerbet.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[0].slides[1].title}</h1>
            <div className="slide-copy-wrap">
                <div className="slide-copy">
                    <p>Thanks to an <span>increased hydration number,</span> Elucirem™ has <span>2- to 3-fold greater relaxivity</span> than currently approved GBCAs<sup>1</sup></p>
                </div>
            </div>

            <div className="image-wrap flex-center">
                <div className="col-wrap">
                    <div className="col image-col fade">
                        <img src={require('../../../assets/images/slide01-1.png')} alt="Graph" />
                        <div className="expand"></div>
                    </div>
                    <div className="col text-col">
                        <p>Based on the results of a phase IIb study, it was hypothesized that <strong>Elucirem™ at 0.05 mmol/kg</strong> should provide <strong>superiority compared to unenhanced MRI</strong> and <strong>non-inferiority compared to gadobutrol at 0.1 mmol/kg</strong> in terms of lesion visualization.</p>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide01;