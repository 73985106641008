import React, { useContext } from 'react';

const progressContext = React.createContext();
const progressUpdateContext = React.createContext();
// score
const scoreContext = React.createContext();
const scoreUpdateContext = React.createContext();

export function useProgress() {
   return useContext(progressContext);
}

export function useProgressUpdate() {
    return useContext(progressUpdateContext)
}

// score
export function useScore() {
    return useContext(scoreContext);
 }
 
 export function useScoreUpdate() {
     return useContext(scoreUpdateContext)
 }

export function ProgressProvider({ children }) {

    const [progress,setProgress] = React.useState(null);
    function setProgressData(_progress) {
        setProgress(_progress);
    }
    // score
    const [score,setScore] = React.useState([0,0,0,0]);
    function setScoreData(_score) {
        setScore(_score);
    }
    
    return (

        <progressContext.Provider value={progress}>
            <progressUpdateContext.Provider value={setProgressData}>
                <scoreContext.Provider value={score}>
                    <scoreUpdateContext.Provider value={setScoreData}>
                        {children}
                    </scoreUpdateContext.Provider>
                </scoreContext.Provider>
            </progressUpdateContext.Provider>
        </progressContext.Provider>

    )
}