import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";

import './Slide.css';
import './Slide12.css';

const Slide12 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        console.log(getData.talkingpaper[1].slides[1].title);
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide12">
        <div className="video-wrap">
            { <Player vid={'assets/video/Web-06-Patients.mp4'} poster={'assets/video/poster.jpg'} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[1].slides[2].title}</h1>
            <div className="list-wrap flex-center">
                <div className="outer-wrap fade">
                    <div className="col-wrap">
                        <div className="col text-col fade">
                            <img className="Icon-type-1" src={require('../../../assets/images/slide12-1.png')} alt="Icon" />
                            <h2>260</h2>
                            <p>patients<br />screened</p>
                        </div>
                        <div className="col text-col fade">
                            <img className="Icon-type-1" src={require('../../../assets/images/slide12-2.png')} alt="Icon" />
                            <h2>256</h2>
                            <p>patients<br />randomized</p>
                            <img className="arrow" src={require('../../../assets/images/slide12-5.png')} alt="Arrow" />
                        </div>
                        <div className="col text-col fade">
                            <img className="Icon-type-1" src={require('../../../assets/images/slide12-3.png')} alt="Icon" />
                            <h2>250</h2>
                            <p>received at least<br />one contrast agent</p>
                            <img className="arrow" src={require('../../../assets/images/slide12-5.png')} alt="Arrow" />
                        </div>
                        <div className="col text-col fade">
                            <img className="Icon-type-1" src={require('../../../assets/images/slide12-4.png')} alt="Icon" />
                            <h2>242</h2>
                            <p>completed the trial<br />received 2 contrast agents</p>
                            <img className="arrow" src={require('../../../assets/images/slide12-5.png')} alt="Arrow" />
                        </div>
                    </div>
                    <div className="col-wrap">
                        <div className="col text-col-2 fade">
                            <img className="Icon-type-2" src={require('../../../assets/images/slide12-6.png')} alt="Icon" />
                            <div className="txt">
                                <h2>53.6%</h2>
                                <p>Female</p>
                            </div>
                            
                        </div>
                        <div className="col text-col-2 fade">
                            <img className="Icon-type-2" src={require('../../../assets/images/slide12-7.png')} alt="Icon" />
                            <div className="txt">
                                <h2>57.2 years (18–84)</h2>
                                <p>Mean age</p>
                            </div>
                            
                        </div>
                    </div>
                </div>




            </div>
        </div>
        </div>

    );
};

export default Slide12;