import React from 'react';

import './Slide22.css';

const Slide22_2_popup = () => {
    return (
        <div className="popup">
            <h2>Lesion to Background Ratio</h2>
            <div className="popup-img-wrap">
                <img src={require('../../../assets/images/slide23-2.png')} alt="Lesion to Background Ratio" />
            </div>
        </div>
    );
};

export default Slide22_2_popup;